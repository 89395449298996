import { graphql, Link } from "gatsby";
import React from "react";
// import { GatsbyImage } from "gatsby-plugin-image";
import { ContactParticle, SubLayout } from "../components";

import Seo from "../components/Seo";
import { SubBanner } from "../elements";

const ClinicTemplate = ({ data: { clinic } }) => {
  const info = clinic.acfClinics;
  return (
    <SubLayout>
      <Seo title={info.name} description={info.description} />
      <section className="mb-16 md:mb-24">
        <div className="px-4 py-6 mb-16 lg:px-8 xl:px-16">
          <SubBanner
            bgImage={info.clinicImg.sourceUrl}
            title={info.name}
            bannerTitle={info.name}
          >
            Clinics
          </SubBanner>
          <div className="hidden lg:flex items-center my-6 gap-x-3">
            <Link
              to="/"
              className="text-primary-blue text-xs font-medium underline hover:opacity-80"
            >
              ホーム
            </Link>
            <span>&gt;</span>
            <Link
              to="/clinics"
              className="text-primary-blue text-xs font-medium underline hover:opacity-80"
            >
              施設一覧
            </Link>
            <span>&gt;</span>
            <h1 className="text-xs font-medium">{info.name}</h1>
          </div>
        </div>
        <div className="px-4 max-w-5xl mx-auto">
          <div className="mb-12 relative w-full h-64 mx-auto md:w-96 md:h-72 lg:w-140 lg:h-96">
            <img
              className="w-full h-full object-cover"
              src={info.clinicImg.sourceUrl}
              alt={`${info.name}　${info.doctor}　画像`}
            />
            <div className="absolute inset-x-0 -bottom-4 w-fit-content mx-auto">
              <div className="rounded-md bg-white filter drop-shadow-lg px-4 py-2 flex gap-x-8 items-center justify-evenly text-primary-blue text-sm font-bold md:text-base">
                <span>院長</span>
                <span className="text-base md:text-lg">{info.doctor}</span>
              </div>
            </div>
          </div>
          <div className="mb-8 max-w-2xl mx-auto">
            <div
              className="text-sm tracking-wider leading-relaxed lg:text-base"
              dangerouslySetInnerHTML={{
                __html: `${info.description}`,
              }}
            />
          </div>
          <div
            className={`${
              info.department === "homecare"
                ? "bg-tertiary-blue bg-opacity-50"
                : "bg-primary-gold bg-opacity-20"
            } mb-8 max-w-3xl mx-auto h-px`}
          />
          <div
            className={`${
              info.department === "homecare"
                ? "text-primary-blue"
                : "text-primary-gold"
            } max-w-3xl mx-auto mb-4 text-xl font-medium lg:text-2xl`}
          >
            クリニック概要
          </div>
          <div className="max-w-3xl mx-auto">
            <div>
              <div
                className={`${
                  info.department === "ebisu"
                    ? "bg-primary-gold bg-opacity-5"
                    : "bg-tertiary-blue bg-opacity-50"
                } mb-8 px-4 py-8 lg:px-8`}
              >
                {info.department === "ebisu" ? null : (
                  <div className="mb-6">
                    <span className="font-medium block lg:text-lg">
                      {info.department === "nurse"
                        ? "事務所営業時間"
                        : "診療時間"}
                    </span>
                    <div className="mt-1.5 flex flex-col gap-y-0.5">
                      <div className="w-8 h-0.5 bg-primary-yellow" />
                      <div className="w-8 h-0.5 bg-primary-yellow" />
                      <div className="w-8 h-0.5 bg-primary-yellow" />
                    </div>
                  </div>
                )}
                <div className="mb-4">
                  <span className="block lg:text-lg font-medium mb-4">
                    {info.department === "homecare" ? "内科外来診療受付" : ""}
                  </span>
                  {info.department !== "ebisu" ? (
                    <div>
                      <div className="border-2 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          受付時間
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          月
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          火
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          水
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          木
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          金
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          土
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          日
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          祝
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalMorning}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalMondayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalMondayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalTuesdayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalTuesdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalWednesdayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalWednesdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalThursdayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalThursdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalFridayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalFridayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalSaturdayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalSaturdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalSundayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalSundayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          {info.generalHolidayMorning === "open"
                            ? "◯"
                            : `${
                                info.generalHolidayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalAfternoon}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalMondayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalMondayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalTuesdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalTuesdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalWednesdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalWednesdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalThursdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalThursdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalFridayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalFridayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalSaturdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalSaturdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.generalSundayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalSundayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          {info.generalHolidayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.generalHolidayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          備考欄
                        </span>
                        <span className="w-full flex items-center px-2 md:px-4 col-span-8 h-full border-gray-300 py-1.5 md:py-4">
                          {info.generalReference}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <span className="block lg:text-lg font-medium mb-4">
                    {info.jibika === "true" ? "耳鼻科外来診療受付" : ""}
                  </span>
                  {info.jibika === "true" ? (
                    <div>
                      <div className="border-2 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          受付時間
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          月
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          火
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          水
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          木
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          金
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          土
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          日
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          祝
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaMorning}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaMondayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaMondayMorning === "close" ? "-" : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaTuesdayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaTuesdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaWednesdayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaWednesdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaThursdayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaThursdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaFridayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaFridayMorning === "close" ? "-" : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaSaturdayMorning === "open"
                            ? "◯"
                            : `${
                                info.sjibikaSaturdayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaSundayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaSundayMorning === "close" ? "-" : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          {info.jibikaHolidayMorning === "open"
                            ? "◯"
                            : `${
                                info.jibikaHolidayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaAfternoon}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaMondayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaMondayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaTuesdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaTuesdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaWednesdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaWednesdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaThursdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaThursdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaFridayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaFridayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaSaturdayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaSaturdayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full border-r-2 border-gray-300 py-1.5 md:py-4">
                          {info.jibikaSundayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaSundayAfternoon === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                        <span className="text-center w-full flex items-center justify-center h-full py-1.5 md:py-4">
                          {info.jibikaHolidayAfternoon === "open"
                            ? "◯"
                            : `${
                                info.jibikaHolidayMorning === "close"
                                  ? "-"
                                  : "※"
                              }`}
                        </span>
                      </div>
                      <div className="border-2 border-t-0 border-gray-300 w-full h-full grid grid-cols-10 items-center text-xs md:text-sm font-medium bg-white">
                        <span className="flex items-center justify-center col-span-2 text-center border-r-2 border-gray-300 py-1.5 md:py-4">
                          備考欄
                        </span>
                        <span className="w-full flex items-center px-2 md:px-4 col-span-8 h-full border-gray-300 py-1.5 md:py-4">
                          {info.jibikaReference}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                {info.department === "homecare" ? (
                  <div className="mb-12">
                    <span className="font-medium pb-1 lg:text-lg border-b-2 border-primary-yellow">
                      訪問診療は24時間対応
                    </span>
                  </div>
                ) : null}
                {info.department === "ebisu" ? (
                  <div className="flex flex-col gap-y-8">
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>診療科目</span>
                      </div>
                      <p className="w-full text-base leading-relaxed">
                        美容皮膚科
                      </p>
                    </div>
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>開院</span>
                      </div>
                      <p className="w-full text-base leading-relaxed">
                        2021年3月
                      </p>
                    </div>
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>住所</span>
                      </div>
                      <p className="w-full text-base leading-relaxed">
                        〒{info.postalAddress}
                        <br />
                        {info.streetAddress}
                      </p>
                    </div>
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>TEL</span>
                      </div>
                      <p className="w-full text-lg underline leading-relaxed">
                        03-6450-4531
                      </p>
                    </div>
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>FAX</span>
                      </div>
                      <p className="w-full text-lg underline leading-relaxed">
                        03-6450-4532
                      </p>
                    </div>
                    <div className="md:flex items-start gap-x-8">
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>院長</span>
                      </div>
                      <p className="w-full text-lg leading-relaxed">
                        {info.doctor}
                      </p>
                    </div>
                    <div
                      className={`${
                        info.doctorBackground === null ? "hidden" : ""
                      } md:flex items-start gap-x-8`}
                    >
                      <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium">
                        <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                        <span>院長経歴</span>
                      </div>
                      <div
                        className="w-full text-sm leading-relaxed"
                        dangerouslySetInnerHTML={{
                          __html: `${info.doctorBackground}`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="mb-6">
                      <span className="font-medium block lg:text-lg">
                        サービス
                      </span>
                      <div className="mt-1.5 flex flex-col gap-y-0.5">
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                      </div>
                    </div>
                    {info.department === "nurse" ? (
                      <div className="mb-12 flex flex-col gap-y-8">
                        <p className="tracking-wider leading-relaxed">
                          ・身体の清拭、洗髪、入浴介助、食事や排泄などの介助・指導
                          <br />
                          ・病気や障害の状態、血圧・体温・脈拍などのチェック
                          <br />
                          ・医師の指示による医療処置
                          <br />
                          ・医療機器の管理：在宅酸素、人工呼吸器などの管理
                          <br />
                          ・がん末期や終末期などでも、自宅で過ごせるよう適切なターミナルケア・
                          入れ歯がゆるくはずれやすい
                          <br />
                          ・床ずれ防止の工夫や指導、床ずれの手当て
                          <br />
                          ・拘縮予防や機能の回復、嚥下機能訓練等の在宅リハビリテーション
                          <br />
                          ・認知症による事故防止など、認知症介護の相談・工夫をアドバイス
                          <br />
                          ・ご家族等への介護指導・相談
                          <br />
                          ・低栄養や運動機能低下など介護予防のアドバイス
                          <br />
                        </p>
                      </div>
                    ) : (
                      <div className="mb-12 flex flex-col gap-y-8">
                        <div className="md:flex items-start gap-x-8">
                          <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium">
                            <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                            <span>訪問医療</span>
                          </div>
                          <p className="w-full text-sm leading-relaxed">
                            訪問医療とは、地域診療所や総合病院へ、通院が困難になってきた方を対象に医師が計画的、定期的に訪問して行う医療のことです。グレースホームケアクリニックでは様々な診療科の医師と医療スタッフが、ご自宅に訪問し、患者さんの生活の質を保ちながら診療・ケアを行います。また、総合病院への外来や入院の数を減らし、患者さんご本人だけではなくご家族の負担も減らすようにします。お気軽にご相談ください。
                          </p>
                        </div>
                        <div className="md:flex items-start gap-x-8">
                          <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium">
                            <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                            <span>診療科</span>
                          </div>
                          <p className="w-full text-sm leading-relaxed">
                            {info.field}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="mb-6">
                      <span className="font-medium block lg:text-lg">
                        電話番号
                      </span>
                      <div className="mt-1.5 flex flex-col gap-y-0.5">
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                      </div>
                    </div>
                    <div className="mb-12 flex flex-col gap-y-8">
                      <div className="md:flex items-center gap-x-8">
                        <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                          <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                          <span>
                            {info.department === "homecare" ? "外来" : "TEL"}
                          </span>
                        </div>
                        <p className="w-full text-lg tracking-wider underline leading-relaxed">
                          {info.department === "homecare"
                            ? info.telGairai
                            : info.tel}
                        </p>
                      </div>
                      <div className="md:flex items-center gap-x-8">
                        <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                          <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                          <span>
                            {info.department === "homecare" ? "訪問" : "FAX"}
                          </span>
                        </div>
                        <p className="w-full text-lg tracking-wider underline leading-relaxed">
                          {info.department === "homecare"
                            ? info.telHoumon
                            : info.fax}
                        </p>
                      </div>
                    </div>
                    <div className="mb-6">
                      <span className="font-medium block lg:text-lg">
                        その他
                      </span>
                      <div className="mt-1.5 flex flex-col gap-y-0.5">
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                        <div className="w-8 h-0.5 bg-primary-yellow" />
                      </div>
                    </div>
                    <div className="mb-12 flex flex-col gap-y-8">
                      <div className="md:flex items-start gap-x-8">
                        <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                          <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                          <span>住所</span>
                        </div>
                        <p className="w-full text-base leading-relaxed">
                          〒{info.postalAddress}
                          <br />
                          {info.streetAddress}
                        </p>
                      </div>
                      {info.department === "homecare" ? (
                        <div className="md:flex items-center gap-x-8">
                          <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium md:mb-0">
                            <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                            <span>院長</span>
                          </div>
                          <p className="w-full text-base leading-relaxed">
                            {info.doctor}
                          </p>
                        </div>
                      ) : null}
                      {info.doctorBackground !== null ? (
                        <div className="md:flex items-start gap-x-8">
                          <div className="w-32 flex-shrink-0 flex items-center gap-2 mb-2 font-medium">
                            <div className="w-4 h-0.5 rounded-full bg-primary-red" />
                            <span>院長経歴</span>
                          </div>
                          <div
                            className="w-full text-sm leading-relaxed"
                            dangerouslySetInnerHTML={{
                              __html: `${info.doctorBackground}`,
                            }}
                          ></div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`${
                info.department === "homecare"
                  ? "bg-tertiary-blue bg-opacity-50"
                  : "bg-primary-gold bg-opacity-20"
              } mb-8 max-w-3xl mx-auto h-px`}
            />
            <div className="relative w-full h-full">
              <iframe
                className="w-full h-72 md:h-96 rounded-lg"
                src={info.map}
                allowfullscreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <ContactParticle />
    </SubLayout>
  );
};

export default ClinicTemplate;

export const pageQuery = graphql`
  query ClinicById($id: String!) {
    clinic: wpClinic(id: { eq: $id }) {
      acfClinics {
        name
        department
        field
        description
        doctor
        doctorBackground
        map
        postalAddress
        streetAddress
        telGairai
        telHoumon
        tel
        fax
        generalAfternoon
        generalFridayAfternoon
        generalFridayMorning
        generalHolidayAfternoon
        generalHolidayMorning
        generalMondayAfternoon
        generalMondayMorning
        generalMorning
        generalReference
        generalSaturdayAfternoon
        generalSaturdayMorning
        generalSundayAfternoon
        generalSundayMorning
        generalThursdayAfternoon
        generalThursdayMorning
        generalTuesdayAfternoon
        generalTuesdayMorning
        generalWednesdayAfternoon
        generalWednesdayMorning
        jibika
        jibikaAfternoon
        jibikaFridayAfternoon
        jibikaFridayMorning
        jibikaHolidayAfternoon
        jibikaHolidayMorning
        jibikaMondayAfternoon
        jibikaMondayMorning
        jibikaMorning
        jibikaReference
        jibikaSaturdayAfternoon
        jibikaSaturdayMorning
        jibikaSundayAfternoon
        jibikaSundayMorning
        jibikaThursdayAfternoon
        jibikaThursdayMorning
        jibikaTuesdayAfternoon
        jibikaTuesdayMorning
        jibikaWednesdayAfternoon
        jibikaWednesdayMorning
        clinicImg {
          sourceUrl
        }
        doctorImg {
          sourceUrl
        }
      }
    }
  }
`;
